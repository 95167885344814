import { AxiosResponse } from 'axios';

export type TResponse<T> = Promise<AxiosResponse<T>>;

export enum EEnvironment {
  Stage = 'stage',
  Production = 'production',
  Development = 'development',
}

export const baseHostName =
  process.env.REACT_SOCKET_HOST_NAME ||
  'wss://dev-yoreactions-back.lad-academy.ru';

export const apiUrl =
  process.env.REACT_APP_API || 'https://dev-yoreactions-back.lad-academy.ru';

export const frontUrl =
  process.env.FRONT_URL || 'https://dev-yoreactions.lad-academy.ru';

// https://dev-yoreactions-back.lad-academy.ru';
// https://back.yoreactions.ru';

import { apiUrl } from './types';

export const getBaseUrl = () => {
  return apiUrl;
};

interface IConfig {
  baseUrl: string;
  token: string | null;
}

export const config: IConfig = {
  baseUrl: getBaseUrl(),
  token: null,
};

export const setConfigToken = (token: string | null) => {
  config.token = token;
};

export const getToken = (): string | null => config.token;

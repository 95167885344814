import makeRequest from 'network/instance/makeRequest';
import { IResponseError } from 'network/rest/commonTypes';
import { TResponse } from 'network/instance/types';

export interface IGetSlideResultsRequest {
  slideId: number;
}

export interface IGetSlideResultsRequestPdf {
  slideId: number;
  presentationId: number;
}

export interface IVotingResult {
  choiceId: number;
  score: number;
  respondents: number;
}

export interface IAnswerResult {
  answer: string;
  answerCount: string;
  respondentCount: string;
}

export interface IGetSlideResultsResponseOk {
  slide: {
    id: number;
  };
  choices: IVotingResult[];
  respondents: number;
}

export interface IGetSlideResultsAnswers {
  slide: {
    id: number;
  };
  answers: IAnswerResult[];
  respondents: number;
}

export type TGetSlideResultsResponse =
  | IGetSlideResultsResponseOk
  | IGetSlideResultsAnswers
  | IResponseError;

export const getSlideResults = (
  params: IGetSlideResultsRequest
): TResponse<TGetSlideResultsResponse> => {
  return makeRequest({
    url: `/slide/${params.slideId}/results`,
  });
};

export const getSlideResultsPdf = (
  params: IGetSlideResultsRequestPdf
): TResponse<TGetSlideResultsResponse> => {
  return makeRequest({
    url: `/slide/${params.slideId}/${params.presentationId}/results`,
  });
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  startDemo,
  IStartDemoRequest,
  IStartDemoResponseOk,
} from 'network/rest/creatorDemoSession/startDemo';

import {
  getSlidesRequest,
  IGetSlidesRequest,
  IGetSlidesResponseOk,
} from 'network/rest/presentationEdit/getSlides';
import {
  ISetDemoSlideRequest,
  ISetDemoSlideResponseOk,
  setDemoSlide,
} from 'network/rest/creatorDemoSession/setDemoSlide';
import {
  IStopDemoRequest,
  IStopDemoResponseOk,
  stopDemo,
} from 'network/rest/creatorDemoSession/stopDemo';
import {
  getDemoInfo,
  IGetDemoInfoRequest,
  IGetDemoInfoResponseOk,
} from 'network/rest/creatorDemoSession/getDemoInfo';
import {
  getSlideResults,
  getSlideResultsPdf,
  IGetSlideResultsAnswers,
  IGetSlideResultsRequest,
  IGetSlideResultsRequestPdf,
  IGetSlideResultsResponseOk,
} from 'network/rest/creatorDemoSession/getSlideResults';

import { CREATOR_DEMO_SESSION_ALIAS } from './types';
import {
  getPresentationDemos,
  IGetPresentationDemosRequest,
  IGetPresentationDemosResponseOk,
} from '../../network/rest/creatorDemoSession/getPresentationDemos';

export const startDemoAction = createAsyncThunk<
  IStartDemoResponseOk,
  IStartDemoRequest,
  {
    rejectValue: IResponseError;
  }
>(
  `${CREATOR_DEMO_SESSION_ALIAS}/startDemo`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await startDemo(payload);

      if (response.status === 200) {
        const data = response.data as IStartDemoResponseOk;
        return { ...data };
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

export const getPresentationAction = createAsyncThunk<
  IGetSlidesResponseOk,
  IGetSlidesRequest,
  {
    rejectValue: IResponseError;
  }
>(
  `${CREATOR_DEMO_SESSION_ALIAS}/getPresentation`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSlidesRequest(payload);

      if (response.status === 200) {
        const data = response.data as IGetSlidesResponseOk;
        return { ...data, statusCode: response.status };
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

export const setDemoSlideAction = createAsyncThunk<
  ISetDemoSlideResponseOk,
  ISetDemoSlideRequest,
  {
    rejectValue: IResponseError;
  }
>(
  `${CREATOR_DEMO_SESSION_ALIAS}/setDemoSlide`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await setDemoSlide(payload);

      if (response.status === 200) {
        const data = response.data as ISetDemoSlideResponseOk;
        return { ...data };
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

export const stopDemoAction = createAsyncThunk<
  IStopDemoResponseOk,
  IStopDemoRequest,
  { rejectValue: IResponseError }
>(
  `${CREATOR_DEMO_SESSION_ALIAS}/stopDemo`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await stopDemo(payload);

      if (response.status === 200) {
        const data = response.data as IStopDemoResponseOk;
        return { ...data };
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

export const getSlideResultsAction = createAsyncThunk<
  IGetSlideResultsResponseOk | IGetSlideResultsAnswers,
  IGetSlideResultsRequest,
  { rejectValue: IResponseError }
>(
  `${CREATOR_DEMO_SESSION_ALIAS}/getSlideResults`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSlideResults(payload);

      if (response.status === 200) {
        const data = response.data as
          | IGetSlideResultsResponseOk
          | IGetSlideResultsAnswers;
        return { ...data };
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

export const getSlideResultsPdfAction = createAsyncThunk<
  IGetSlideResultsResponseOk | IGetSlideResultsAnswers,
  IGetSlideResultsRequestPdf,
  { rejectValue: IResponseError }
>(
  `${CREATOR_DEMO_SESSION_ALIAS}/getSlideResultsPdf`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSlideResultsPdf(payload);

      if (response.status === 200) {
        const data = response.data as
          | IGetSlideResultsResponseOk
          | IGetSlideResultsAnswers;
        return { ...data };
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

export const getSlideVotingStatusAction = createAsyncThunk<
  { slideId: number; enableVoting: boolean },
  IGetDemoInfoRequest,
  { rejectValue: IResponseError }
>(
  `${CREATOR_DEMO_SESSION_ALIAS}/getSlideVotingStatus`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getDemoInfo(payload);

      if (response.status === 200) {
        const data = response.data as IGetDemoInfoResponseOk;
        return {
          slideId: data.slide.id,
          enableVoting: data.slide.enableVoting,
        };
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

export const getCurrentDemoSlideAction = createAsyncThunk<
  { slideId: number },
  IGetDemoInfoRequest,
  { rejectValue: IResponseError }
>(
  `${CREATOR_DEMO_SESSION_ALIAS}/getCurrentDemoSlide`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getDemoInfo(payload);

      if (response.status === 200) {
        const data = response.data as IGetDemoInfoResponseOk;
        return { slideId: data.slide.id };
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

export const setSlideVotingStatusAction = createAsyncThunk<
  { slideId: number; enableVoting: boolean; statusCode: number },
  ISetDemoSlideRequest,
  { rejectValue: IResponseError }
>(
  `${CREATOR_DEMO_SESSION_ALIAS}/setSlideVotingStatus`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await setDemoSlide(payload);

      if (response.status === 200) {
        const data = response.data as ISetDemoSlideResponseOk;
        return {
          statusCode: response.status,
          slideId: data.slide.id,
          enableVoting: data.slide.enableVoting,
        };
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

export const getPresentationDemosAction = createAsyncThunk<
  { demos: IGetPresentationDemosResponseOk[]; status: number },
  IGetPresentationDemosRequest,
  { rejectValue: IResponseError }
>(
  `${CREATOR_DEMO_SESSION_ALIAS}/getPresentationDemos`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getPresentationDemos(payload);

      if (response.status === 200) {
        const data = response.data as IGetPresentationDemosResponseOk[];
        return { demos: data, status: response.status };
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

export interface IGetSlideIdRequest {
  slideId: number;
}

export interface IGetVoitesResultOK {
  answer: string;
}

export interface IResponseError {
  statusCode: number;
  error: string;
  message: string;
}

export type TSlideInfoResponse = IGetVoitesResultOK | IResponseError;
